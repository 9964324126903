import React from "react";

export default function MainContent() {
  return (
    <div>
      {/*Main layout*/}
      <main className="mb-6">
        <div className="container">
          {/* Section: Categories */}
          <section className="mb-6">
            <div className="row mb-4">
              <div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
                <div
                  className="bg-image hover-zoom ripple shadow-4 rounded-6"
                  data-ripple-color="light"
                >
                  <img
                    src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/new/img(1).jpg"
                    className="w-100"
                  />
                  <a href="#!">
                    <div
                      className="mask"
                      style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
                    >
                      <div className="d-flex justify-content-start align-items-end h-100">
                        <h5 className="text-white m-4">Dresses</h5>
                      </div>
                    </div>
                    <div className="hover-overlay">
                      <div
                        className="mask"
                        style={{ backgroundColor: "hsla(0, 0%, 98.4%, 0.12)" }}
                      />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                <div
                  className="bg-image hover-zoom ripple shadow-4 rounded-6"
                  data-ripple-color="light"
                >
                  <img
                    src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/new/img(2).jpg"
                    className="w-100"
                  />
                  <a href="#!">
                    <div
                      className="mask"
                      style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
                    >
                      <div className="d-flex justify-content-start align-items-end h-100">
                        <h5 className="text-white m-4">Shirts</h5>
                      </div>
                    </div>
                    <div className="hover-overlay">
                      <div
                        className="mask"
                        style={{ backgroundColor: "hsla(0, 0%, 98.4%, 0.12)" }}
                      />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                <div
                  className="bg-image hover-zoom ripple shadow-4 rounded-6"
                  data-ripple-color="light"
                >
                  <img
                    src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/new/img(3).jpg"
                    className="w-100"
                  />
                  <a href="#!">
                    <div
                      className="mask"
                      style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
                    >
                      <div className="d-flex justify-content-start align-items-end h-100">
                        <h5 className="text-white m-4">Jeans</h5>
                      </div>
                    </div>
                    <div className="hover-overlay">
                      <div
                        className="mask"
                        style={{ backgroundColor: "hsla(0, 0%, 98.4%, 0.12)" }}
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
                <div
                  className="bg-image hover-zoom ripple shadow-4 rounded-6"
                  data-ripple-color="light"
                >
                  <img
                    src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/new/img(4).jpg"
                    className="w-100"
                  />
                  <a href="#!">
                    <div
                      className="mask"
                      style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
                    >
                      <div className="d-flex justify-content-start align-items-end h-100">
                        <h5 className="text-white m-4">Shoes</h5>
                      </div>
                    </div>
                    <div className="hover-overlay">
                      <div
                        className="mask"
                        style={{ backgroundColor: "hsla(0, 0%, 98.4%, 0.12)" }}
                      />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                <div
                  className="bg-image hover-zoom ripple shadow-4 rounded-6"
                  data-ripple-color="light"
                >
                  <img
                    src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/new/img(5).jpg"
                    className="w-100"
                  />
                  <a href="#!">
                    <div
                      className="mask"
                      style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
                    >
                      <div className="d-flex justify-content-start align-items-end h-100">
                        <h5 className="text-white m-4">Accessories</h5>
                      </div>
                    </div>
                    <div className="hover-overlay">
                      <div
                        className="mask"
                        style={{ backgroundColor: "hsla(0, 0%, 98.4%, 0.12)" }}
                      />
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                <div
                  className="bg-image hover-zoom ripple shadow-4 rounded-6"
                  data-ripple-color="light"
                >
                  <img
                    src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/new/img(6).jpg"
                    className="w-100"
                  />
                  <a href="#!">
                    <div
                      className="mask"
                      style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
                    >
                      <div className="d-flex justify-content-start align-items-end h-100">
                        <h5 className="text-white m-4">Jewelry</h5>
                      </div>
                    </div>
                    <div className="hover-overlay">
                      <div
                        className="mask"
                        style={{ backgroundColor: "hsla(0, 0%, 98.4%, 0.12)" }}
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>
          {/* Section: Categories */}
          {/* Section: Bestsellers */}
          <section className="mb-6">
            <h5 className="text-center fw-bold mb-6">
              <i className="fas fa-fire me-2 text-warning" />
              Bestsellers
            </h5>
            <div className="row gx-xl-5 justify-content-center">
              <div className="col-lg-3 col-6 mb-4 mb-xl-0">
                {/* Product card */}
                <div>
                  {/* Product image */}
                  <div
                    className="
                    bg-image
                    ripple
                    shadow-4
                    rounded-6
                    mb-4
                    overflow-hidden
                    d-block
                    "
                    data-ripple-color="light"
                  >
                    <img
                      src="https://mdbootstrap.com/img/new/ecommerce/vertical/042.jpg"
                      className="w-100"
                      alt=""
                    />
                    <a href="#!">
                      <div className="mask">
                        <div
                          className="
                          d-flex
                          justify-content-start
                          align-items-end
                          h-100
                          p-3
                          "
                        >
                          <span className="badge badge-danger rounded-pill me-2">
                            -15%
                          </span>
                        </div>
                      </div>
                      <div className="hover-overlay">
                        <div
                          className="mask"
                          style={{
                            backgroundColor: "hsla(0, 0%, 98.4%, 0.09)",
                          }}
                        />
                      </div>
                    </a>
                  </div>
                  {/* Product content */}
                  <a href className="px-3 text-reset d-block">
                    <p className="fw-bold mb-2">Elegant dress</p>
                    <p className="text-muted mb-2">Dresses</p>
                    <ul
                      className="rating mb-2"
                      data-mdb-toggle="rating"
                      data-mdb-readonly="true"
                      data-mdb-value={4}
                    >
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary ps-0"
                          title="Bad"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="Poor"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="OK"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="Good"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="Excellent"
                        />
                      </li>
                    </ul>
                    <h5 className="mb-2">
                      <s className="text-muted me-2 small align-middle">$119</s>
                      <span className="align-middle">$101</span>
                    </h5>
                  </a>
                  {/* Product content */}
                </div>
                {/* Product card */}
              </div>
              <div className="col-lg-3 col-6 mb-4 mb-lg-0">
                {/* Product card */}
                <div>
                  {/* Product image */}
                  <div
                    className="
                    bg-image
                    ripple
                    shadow-4
                    rounded-6
                    mb-4
                    overflow-hidden
                    d-block
                    "
                    data-ripple-color="light"
                  >
                    <img
                      src="https://mdbootstrap.com/img/new/ecommerce/vertical/085.jpg"
                      className="w-100"
                      alt=""
                    />
                    <a href="#!">
                      <div className="mask">
                        <div
                          className="
                          d-flex
                          justify-content-start
                          align-items-end
                          h-100
                          p-3
                          "
                        >
                          <span className="badge badge-primary rounded-pill me-2">
                            New
                          </span>
                          <span className="badge badge-success rounded-pill">
                            Eco
                          </span>
                        </div>
                      </div>
                      <div className="hover-overlay">
                        <div
                          className="mask"
                          style={{
                            backgroundColor: "hsla(0, 0%, 98.4%, 0.09)",
                          }}
                        />
                      </div>
                    </a>
                  </div>
                  {/* Product content */}
                  <a href className="px-3 text-reset d-block">
                    <p className="fw-bold mb-2">Sophisticated dress</p>
                    <p className="text-muted mb-2">Dresses</p>
                    <ul
                      className="rating mb-2"
                      data-mdb-toggle="rating"
                      data-mdb-readonly="true"
                      data-mdb-value={5}
                    >
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary ps-0"
                          title="Bad"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="Poor"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="OK"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="Good"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="Excellent"
                        />
                      </li>
                    </ul>
                    <h5 className="mb-2">
                      <span className="align-middle">$399</span>
                    </h5>
                  </a>
                  {/* Product content */}
                </div>
                {/* Product card */}
              </div>
              <div className="col-lg-3 col-6 mb-4 mb-lg-0">
                {/* Product card */}
                <div>
                  {/* Product image */}
                  <div
                    className="
                    bg-image
                    ripple
                    shadow-4
                    rounded-6
                    mb-4
                    overflow-hidden
                    d-block
                    "
                    data-ripple-color="light"
                  >
                    <img
                      src="https://mdbootstrap.com/img/new/ecommerce/vertical/069.jpg"
                      className="w-100"
                      alt=""
                    />
                    <a href="#!">
                      <div className="mask">
                        <div
                          className="
                          d-flex
                          justify-content-start
                          align-items-end
                          h-100
                          p-3
                          "
                        />
                      </div>
                      <div className="hover-overlay">
                        <div
                          className="mask"
                          style={{
                            backgroundColor: "hsla(0, 0%, 98.4%, 0.09)",
                          }}
                        />
                      </div>
                    </a>
                  </div>
                  {/* Product content */}
                  <a href className="px-3 text-reset d-block">
                    <p className="fw-bold mb-2">Stylish dress</p>
                    <p className="text-muted mb-2">Dresses</p>
                    <ul
                      className="rating mb-2"
                      data-mdb-toggle="rating"
                      data-mdb-readonly="true"
                      data-mdb-value={4}
                    >
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary ps-0"
                          title="Bad"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="Poor"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="OK"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="Good"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="Excellent"
                        />
                      </li>
                    </ul>
                    <h5 className="mb-2">
                      <span className="align-middle">$249</span>
                    </h5>
                  </a>
                  {/* Product content */}
                </div>
                {/* Product card */}
              </div>
              <div className="col-lg-3 col-6 mb-4 mb-xl-0">
                {/* Product card */}
                <div>
                  {/* Product image */}
                  <div
                    className="
                    bg-image
                    ripple
                    shadow-4
                    rounded-6
                    mb-4
                    overflow-hidden
                    d-block
                    "
                    data-ripple-color="light"
                  >
                    <img
                      src="https://mdbootstrap.com/img/new/ecommerce/vertical/013.jpg"
                      className="w-100"
                      alt=""
                    />
                    <a href="#!">
                      <div className="mask">
                        <div
                          className="
                          d-flex
                          justify-content-start
                          align-items-end
                          h-100
                          p-3
                          "
                        >
                          <span className="badge badge-danger rounded-pill me-2">
                            -15%
                          </span>
                        </div>
                      </div>
                      <div className="hover-overlay">
                        <div
                          className="mask"
                          style={{
                            backgroundColor: "hsla(0, 0%, 98.4%, 0.09)",
                          }}
                        />
                      </div>
                    </a>
                  </div>
                  {/* Product content */}
                  <a href className="px-3 text-reset d-block">
                    <p className="fw-bold mb-2">Fashionable dress</p>
                    <p className="text-muted mb-2">Dresses</p>
                    <ul
                      className="rating mb-2"
                      data-mdb-toggle="rating"
                      data-mdb-readonly="true"
                      data-mdb-value={4}
                    >
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary ps-0"
                          title="Bad"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="Poor"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="OK"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="Good"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="Excellent"
                        />
                      </li>
                    </ul>
                    <h5 className="mb-2">
                      <s className="text-muted me-2 small align-middle">$119</s>
                      <span className="align-middle">$101</span>
                    </h5>
                  </a>
                  {/* Product content */}
                </div>
                {/* Product card */}
              </div>
            </div>
          </section>
          {/* Section: Bestsellers */}
          {/* Section: Eco */}
          <section className="mb-6">
            <h5 className="text-center fw-bold mb-6">
              <i className="fas fa-leaf me-2 text-success" />
              Eco
            </h5>
            <div className="row gx-xl-5 justify-content-center">
              <div className="col-lg-3 col-6 mb-4 mb-xl-0">
                {/* Product card */}
                <div>
                  {/* Product image */}
                  <div
                    className="
                    bg-image
                    ripple
                    shadow-4
                    rounded-6
                    mb-4
                    overflow-hidden
                    d-block
                    "
                    data-ripple-color="light"
                  >
                    <img
                      src="https://mdbootstrap.com/img/new/ecommerce/vertical/009.jpg"
                      className="w-100"
                      alt=""
                    />
                    <a href="#!">
                      <div className="mask">
                        <div
                          className="
                          d-flex
                          justify-content-start
                          align-items-end
                          h-100
                          p-3
                          "
                        >
                          <span className="badge badge-danger rounded-pill me-2">
                            -15%
                          </span>
                          <span className="badge badge-success rounded-pill">
                            Eco
                          </span>
                        </div>
                      </div>
                      <div className="hover-overlay">
                        <div
                          className="mask"
                          style={{
                            backgroundColor: "hsla(0, 0%, 98.4%, 0.09)",
                          }}
                        />
                      </div>
                    </a>
                  </div>
                  {/* Product content */}
                  <a href className="px-3 text-reset d-block">
                    <p className="fw-bold mb-2">Red dress</p>
                    <p className="text-muted mb-2">Dresses</p>
                    <ul
                      className="rating mb-2"
                      data-mdb-toggle="rating"
                      data-mdb-readonly="true"
                      data-mdb-value={4}
                    >
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary ps-0"
                          title="Bad"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="Poor"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="OK"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="Good"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="Excellent"
                        />
                      </li>
                    </ul>
                    <h5 className="mb-2">
                      <s className="text-muted me-2 small align-middle">$119</s>
                      <span className="align-middle">$101</span>
                    </h5>
                  </a>
                  {/* Product content */}
                </div>
                {/* Product card */}
              </div>
              <div className="col-lg-3 col-6 mb-4 mb-lg-0">
                {/* Product card */}
                <div>
                  {/* Product image */}
                  <div
                    className="
                    bg-image
                    ripple
                    shadow-4
                    rounded-6
                    mb-4
                    overflow-hidden
                    d-block
                    "
                    data-ripple-color="light"
                  >
                    <img
                      src="https://mdbootstrap.com/img/new/ecommerce/vertical/015.jpg"
                      className="w-100"
                      alt=""
                    />
                    <a href="#!">
                      <div className="mask">
                        <div
                          className="
                          d-flex
                          justify-content-start
                          align-items-end
                          h-100
                          p-3
                          "
                        >
                          <span className="badge badge-primary rounded-pill me-2">
                            New
                          </span>
                          <span className="badge badge-success rounded-pill">
                            Eco
                          </span>
                        </div>
                      </div>
                      <div className="hover-overlay">
                        <div
                          className="mask"
                          style={{
                            backgroundColor: "hsla(0, 0%, 98.4%, 0.09)",
                          }}
                        />
                      </div>
                    </a>
                  </div>
                  {/* Product content */}
                  <a href className="px-3 text-reset d-block">
                    <p className="fw-bold mb-2">hort jeans</p>
                    <p className="text-muted mb-2">Shorts</p>
                    <ul
                      className="rating mb-2"
                      data-mdb-toggle="rating"
                      data-mdb-readonly="true"
                      data-mdb-value={5}
                    >
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary ps-0"
                          title="Bad"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="Poor"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="OK"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="Good"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="Excellent"
                        />
                      </li>
                    </ul>
                    <h5 className="mb-2">
                      <span className="align-middle">$399</span>
                    </h5>
                  </a>
                  {/* Product content */}
                </div>
                {/* Product card */}
              </div>
              <div className="col-lg-3 col-6 mb-4 mb-lg-0">
                {/* Product card */}
                <div>
                  {/* Product image */}
                  <div
                    className="
                    bg-image
                    ripple
                    shadow-4
                    rounded-6
                    mb-4
                    overflow-hidden
                    d-block
                    "
                    data-ripple-color="light"
                  >
                    <img
                      src="https://mdbootstrap.com/img/new/ecommerce/vertical/027.jpg"
                      className="w-100"
                      alt=""
                    />
                    <a href="#!">
                      <div className="mask">
                        <div
                          className="
                          d-flex
                          justify-content-start
                          align-items-end
                          h-100
                          p-3
                          "
                        >
                          <span className="badge badge-success rounded-pill">
                            Eco
                          </span>
                        </div>
                      </div>
                      <div className="hover-overlay">
                        <div
                          className="mask"
                          style={{
                            backgroundColor: "hsla(0, 0%, 98.4%, 0.09)",
                          }}
                        />
                      </div>
                    </a>
                  </div>
                  {/* Product content */}
                  <a href className="px-3 text-reset d-block">
                    <p className="fw-bold mb-2">Denim skirt</p>
                    <p className="text-muted mb-2">Skirts</p>
                    <ul
                      className="rating mb-2"
                      data-mdb-toggle="rating"
                      data-mdb-readonly="true"
                      data-mdb-value={4}
                    >
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary ps-0"
                          title="Bad"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="Poor"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="OK"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="Good"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="Excellent"
                        />
                      </li>
                    </ul>
                    <h5 className="mb-2">
                      <span className="align-middle">$249</span>
                    </h5>
                  </a>
                  {/* Product content */}
                </div>
                {/* Product card */}
              </div>
              <div className="col-lg-3 col-6 mb-4 mb-xl-0">
                {/* Product card */}
                <div>
                  {/* Product image */}
                  <div
                    className="
                    bg-image
                    ripple
                    shadow-4
                    rounded-6
                    mb-4
                    overflow-hidden
                    d-block
                    "
                    data-ripple-color="light"
                  >
                    <img
                      src="https://mdbootstrap.com/img/new/ecommerce/vertical/058.jpg"
                      className="w-100"
                      alt=""
                    />
                    <a href="#!">
                      <div className="mask">
                        <div
                          className="
                          d-flex
                          justify-content-start
                          align-items-end
                          h-100
                          p-3
                          "
                        >
                          <span className="badge badge-danger rounded-pill me-2">
                            -15%
                          </span>
                          <span className="badge badge-success rounded-pill">
                            Eco
                          </span>
                        </div>
                      </div>
                      <div className="hover-overlay">
                        <div
                          className="mask"
                          style={{
                            backgroundColor: "hsla(0, 0%, 98.4%, 0.09)",
                          }}
                        />
                      </div>
                    </a>
                  </div>
                  {/* Product content */}
                  <a href className="px-3 text-reset d-block">
                    <p className="fw-bold mb-2">Sensual dress</p>
                    <p className="text-muted mb-2">Dresses</p>
                    <ul
                      className="rating mb-2"
                      data-mdb-toggle="rating"
                      data-mdb-readonly="true"
                      data-mdb-value={4}
                    >
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary ps-0"
                          title="Bad"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="Poor"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="OK"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="Good"
                        />
                      </li>
                      <li>
                        <i
                          className="far fa-star fa-sm text-primary"
                          title="Excellent"
                        />
                      </li>
                    </ul>
                    <h5 className="mb-2">
                      <s className="text-muted me-2 small align-middle">$119</s>
                      <span className="align-middle">$101</span>
                    </h5>
                  </a>
                  {/* Product content */}
                </div>
                {/* Product card */}
              </div>
            </div>
          </section>
          {/* Section: Eco */}
        </div>
      </main>
      {/*Main layout*/}
    </div>
  );
}
