import React from "react";
import Footer from "../components/Footer";
import MainContent from "../components/MainContent";
import TopNavigation from "../components/TopNavigation";

export default function Home() {
  return (
    <div>
      <TopNavigation />
      <MainContent />
      <Footer />
    </div>
  );
}
