import React from "react";

export default function TopNavigation() {
  return (
    <div>
      {/*Main Navigation*/}
      <header className="mb-7">
        {/* Jumbotron */}
        <div className="p-3 text-center bg-white border-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-4 d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
                <a href="#!" className="ms-md-2">
                  <img
                    src="/logo.png"
                    height={35}
                  />
                </a>
              </div>
              <div className="col-md-4">
                <form className="d-flex input-group w-auto my-auto mb-3 mb-md-0">
                  <input
                    autoComplete="off"
                    type="search"
                    className="form-control rounded"
                    placeholder="Search"
                  />
                  <span className="input-group-text border-0 d-none d-lg-flex">
                    <i className="fas fa-search" />
                  </span>
                </form>
              </div>
              <div className="col-md-4 d-flex justify-content-center justify-content-md-end align-items-center">
                <div className="d-flex">
                  {/* Cart */}
                  <a className="text-reset me-3" href="#">
                    <span>
                      <i className="fas fa-shopping-cart" />
                    </span>
                    <span className="badge rounded-pill badge-notification bg-danger">
                      1
                    </span>
                  </a>
                  {/* Notification */}
                  <div className="dropdown">
                    <a
                      className="text-reset me-3 dropdown-toggle hidden-arrow"
                      href="#"
                      id="navbarDropdownMenuLink"
                      role="button"
                      data-mdb-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="fas fa-bell" />
                    </a>
                    <ul
                      className="dropdown-menu dropdown-menu-end"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          Some news
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another news
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* Languages */}
                  <div className="dropdown">
                    <a
                      className="text-reset dropdown-toggle me-3 hidden-arrow"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-mdb-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="flag-united-kingdom flag m-0" />
                    </a>
                    <ul
                      className="dropdown-menu dropdown-menu-end"
                      aria-labelledby="navbarDropdown"
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          <i className="flag-united-kingdom flag" />
                          English
                          <i className="fa fa-check text-success ms-2" />
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <i className="flag-poland flag" />
                          Polski
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <i className="flag-china flag" />
                          中文
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <i className="flag-japan flag" />
                          日本語
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <i className="flag-germany flag" />
                          Deutsch
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <i className="flag-france flag" />
                          Français
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <i className="flag-spain flag" />
                          Español
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <i className="flag-russia flag" />
                          Русский
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          <i className="flag-portugal flag" />
                          Português
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* User */}
                  <div className="dropdown">
                    <a
                      className="text-reset dropdown-toggle d-flex align-items-center hidden-arrow"
                      href="#"
                      id="navbarDropdownMenuLink"
                      role="button"
                      data-mdb-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="https://mdbootstrap.com/img/Photos/Avatars/img (31).jpg"
                        className="rounded-circle"
                        height={22}
                        alt=""
                        loading="lazy"
                      />
                    </a>
                    <ul
                      className="dropdown-menu dropdown-menu-end"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          My profile
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Settings
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Jumbotron */}

        {/* Sidenav */}
        {/* Background image */}
        <div
          id="intro"
          className="bg-image shadow-1-strong"
          style={{
            backgroundImage:
              "url(https://mdbootstrap.com/img/new/slides/310.jpg)",
            height: "500px",
          }}
        >
          <div
            className="mask text-white"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
          >
            <div className="container d-flex align-items-center justify-content-center text-center h-100">
              <div className="text-white">
                <h1 className="mb-3">Autumn sale</h1>
                <h4 className="mb-4">Promotions up to 70%!</h4>
                <a
                  className="btn btn-outline-light btn-lg mb-3"
                  href="#!"
                  role="button"
                >
                  See the promotional offer
                  <i className="fas fa-gem ms-1" />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Background image */}
      </header>
      {/*Main Navigation*/}
    </div>
  );
}
